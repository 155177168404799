
// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

input.error{
    border-color: $brand-danger !important;
}

.navbar-brand{
    // padding: 4px 15px;
}

[data-toggle="buttons"] {
    label.active{
        font-weight: bold;
        background-color: darken($brand-info, 25%);
        border-color: darken($brand-info, 30%);
    }
}